export default {
  filterConfig: {},
  filterParams: {},
  queries: {},
  languages: [],
  translations: {
    header_route_carousels: 'Carousels',
    header_route_games: 'All Games',
    header_route_popular: 'Popular',
    header_route_groups: 'Groups',
    filter_button_reset_filter: 'Reset Filter',
    filter_search_field: 'Search by text',
    filter_company_dropdown: 'Company',
    filter_category_dropdown: 'Category',
    filter_type_dropdown: 'Type',
    filter_aggregator_dropdown: 'Aggregator',
    filter_provider_dropdown: 'Provider',
    filter_status_dropdown: 'Status',
    filter_activity_dropdown: 'Activity',
    filter_enabled_dropdown: 'Enabled',
    filter_new_only_selector: 'New only',
    filter_enabled_selector: 'Enabled',
    filter_mobile_selector: 'Mobile',
    filter_desktop_selector: 'Desktop',
    filter_live_selector: 'Live',
    filter_standalone_selector: 'Standalone',
    form_not_valid: 'Form not valid',
    form_field_already_exists: 'This {fieldName} already exists',
    form_field_duplicate_revert_changes_info: 'Your {fieldName} name "{invalidValue}" is already in use.\nEdit {fieldName} or finish and revert {fieldName} to "{validValue}".',
    form_field_empty_revert_changes_info: 'Your {fieldName} is empty, fill it out or finish and revert {fieldName} to "{validValue}".',
    form_field_max_characters_warning: 'The {field} field may not be greater than {maxCharacters} characters',
    form_field_must_not_end_in_underscore: 'This field must not end in underscore!',
    form_field_must_not_end_in_underscore_revert_info: 'Your {fieldName} name "{invalidValue}" must not end in underscore, fix it or finish and revert {fieldName} to "{validValue}".',
    form_field_not_valid: '{fieldName} not valid',
    form_field_not_valid_two: '{fieldName1} and {fieldName2} are not valid',
    form_field_only_small_caps_and_underscores: 'This field can contain only small caps and underscores!',
    form_field_only_small_caps_and_underscore_revert_info: 'Your {fieldName} name "{invalidValue}" can contain only small caps and underscores, fix it or finish and revert {fieldName} to "{validValue}".',
    all_games_promoted_games: 'Promoted games',
    all_games_games: 'Games',
    all_games_no_games: 'No game found. Please select different filters!',
    promoted_games_no_games: 'No promoted games yet!',
    all_games_select_company: 'Before start, please select Company!',
    all_games_select_promoted_game: 'Select game from the list',
    all_games_new_game_label: 'New',
    all_games_promoted_game_label: 'Promoted',
    all_games_promote_game_label: 'Promote',
    container_create_icons_info: 'Container or icons missing. Please contact your Client Success Manager for more details.',
    edit_modal_edit_title: 'Edit',
    edit_modal_game_origin_name: 'Origin name',
    edit_modal_game_display_name: 'Display name',
    edit_modal_game_description: 'Description',
    edit_modal_category_title: 'Category',
    edit_modal_image_url: 'Image URL',
    edit_modal_desktop_image_url: 'Override Desktop Image URL',
    edit_modal_mobile_image_url: 'Override Mobile Image URL',
    edit_modal_sticker_image_url: 'Sticker Image URL',
    edit_modal_new_label: 'New',
    edit_modal_promoted_label: 'Promoted',
    edit_modal_popular_label: 'Popular',
    edit_modal_active_label: 'Active',
    edit_modal_id_label: 'Id',
    edit_modal_priority_label: 'Priority',
    edit_modal_aggregator_label: 'Aggregator',
    edit_modal_provider_label: 'Provider',
    edit_modal_cancel_edit: 'Cancel',
    edit_modal_update_game: 'Update game',
    edit_modal_standalone_label: 'Standalone',
    edit_modal_visible_to_all_users_label: 'Visible to all users',
    enter_display_id: 'Enter display ID',
    enter_position: 'Enter position',
    enter_title: 'Enter title',
    entity_field_already_exists_notification_info: '{entityName} with the same {fieldName} already exists. Please enter a different {fieldName}.',
    popular_page_title: 'Select popular games',
    promoted_order_updated: 'Promoted games order updated!',
    popular_order_updated: 'Popular games order updated!',
    order_updated: 'Games order updated',
    game_updated: 'Game updated!',
    popular_game_updated: 'Popular game updated',
    promoted_game_updated: 'Promoted game updated',
    general_active: 'Active',
    general_activity: 'Activity',
    general_add_games: 'Add games',
    general_added_games: 'Added games',
    general_aggregator: 'Aggregator',
    general_all_games: 'All games',
    general_apply: 'Apply',
    general_autosaving: 'Autosaving...',
    general_cancel: 'Cancel',
    general_changes_saved: 'Changes saved',
    general_channel: 'Channel',
    general_close: 'Close',
    general_container: 'Container',
    general_created: 'Created',
    general_details: 'Details',
    general_disabled: 'Disabled',
    general_display_id: 'Display ID',
    general_done: 'Done',
    general_enabled: 'Enabled',
    general_finish: 'Finish',
    general_group: 'Group',
    general_inactive: 'Inactive',
    general_off: 'Off',
    general_on: 'On',
    general_position: 'Position',
    general_provider: 'Provider',
    general_save: 'Save',
    general_subgroup: 'Subgroup',
    general_games_only: 'Games only',
    general_state: 'State',
    general_last_update: 'Last update',
    groups_id: '#',
    groups_title: 'Title',
    groups_name: 'Name',
    groups_translation: 'Title translation key',
    group_modal_value_none: 'None',
    edit_modal_updatedAt_label: 'Updated at:',
    edit_modal_createdAt_label: 'Created at:',
    groups_active: 'Active',
    groups_edit: 'Edit',
    group_games_select_all: 'Select all',
    add_group_modal_title: 'Add group',
    add_group_modal_group_translation: 'Title translation key',
    add_group_modal_cancel_adding: 'Cancel',
    add_group_modal_add_group: 'Add group',
    edit_modal_group_title: 'Groups',
    filter_group_dropdown: 'Group',
    groups_no_groups: 'Selected company has no groups yet!',
    groups_order_updated: 'Groups order updated!',
    group_updated: 'Group updated',
    group_active: 'Active',
    group_add_to_group: 'Add to group',
    group_add_games: 'Add games to group',
    group_added: 'Group added',
    group_activity_notification_info: 'Successfully updated group activity',
    group_add_games_info: 'Select the games that you want to add to your group',
    group_create: 'Create group',
    group_created: 'Group created',
    group_created_info: 'Group {groupTitle} created. Start creating subgroups.',
    group_created_notification_info: 'Group {groupTitle} created',
    group_deleted_notification_info: 'Group {groupTitle} deleted',
    group_delete: 'Delete group',
    group_delete_info: 'Are you sure you want to delete group {groupTitle}?\nYou will also delete {totalSubgroups} subgroups with total of {totalGroupGames} games.',
    group_edit: 'Edit group',
    group_games_search: 'Search',
    group_in_group: 'In Group',
    group_remove_from_group: 'Remove from group',
    group_structure: 'Group structure',
    group_table_footer_info: 'Explore more. Search and use filters to easily find specific games.',
    group_updated_notification_info: 'Group {groupTitle} updated',
    no_search_results: 'No search results',
    no_search_results_icons_info: 'We couldn\'t find any icons matching your search.',
    subgroup_create: 'Create subgroup',
    subgroup_delete_info: 'Are you sure you want to delete subgroup {subgroupTitle}?\n{subgroupTitle} has {totalSubgroupGames} games.',
    subgroup_edit: 'Edit subgroup',
    subgroups_create: 'Create subgroups',
    subgroups_no_subgroups: 'No subgroups',
    subgroups_no_subgroups_info: 'Currently you don\'t have any subgroups created.',
    other_changes_will_be_saved: 'Other changes will be saved.',
    session_expired_notification_info: 'Session expired due to inactivity. Please try again.',
    header_route_tpagames: 'TPA Casino games',
    carousels_page_title: 'Carousels',
    add_carousel_label: 'Add carousel',
    add_icon: 'Add icon',
    carousel_type_label: 'Carousel type',
    carousel_title_label: 'Carousel title',
    carousel_number_of_games_label: 'Number of games',
    delete_label: 'Delete',
    delete_prompt: 'Are you sure you want to delete selected object?',
    confirm_label: 'Confirm',
    confirm_action: 'Confirm action',
    recently_played: 'Recently Played',
    recommended_for_you: 'Recommended For You',
    top_n: 'Top X in country',
    tournament: 'Tournament Games',
    trending: 'Trending This Week',
    new_releases: 'New Releases',
    because_you_played: 'Because You Played',
    supplier_of_the_month: 'Supplier Of The Month',
    hottest_games: 'Our hottest titles',
  },
  isDragging: false,
  isLoaderVisible: false,
  statusMessage: {},
  user: {},
  userModules: {},
};
