export default {
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_FILTER_CONFIG: 'SET_FILTER_CONFIG',

  // games module
  SET_GAMES: 'SET_GAMES',
  SET_GAME: 'SET_GAME',
  SET_GAME_LANGUAGE_DATA: 'SET_GAME_LANGUAGE_DATA',
  SET_INITIAL_GAME_LANGUAGE_DATA: 'SET_INITIAL_GAME_LANGUAGE_DATA',

  SET_GROUPS: 'SET_GROUPS',
  SET_GROUP: 'SET_GROUP',
  SET_AUDIT_GROUPS: 'SET_AUDIT_GROUPS',
  UPDATE_GROUP: 'UPDATE_GROUP',
  SET_ADDED_GROUP_GAMES_DATA: 'SET_ADDED_GROUP_GAMES_DATA',
  TOGGLE_ADDED_GROUP_GAMES_LOADING: 'TOGGLE_ADDED_GROUP_GAMES_LOADING',
  TOGGLE_ALL_GROUP_TEMP_GAMES_LOADING: 'TOGGLE_ALL_GROUP_TEMP_GAMES_LOADING',
  TOGGLE_GROUP_AUTOSAVING: 'TOGGLE_GROUP_AUTOSAVING',
  TOGGLE_GROUP_CHANGES_SAVED: 'TOGGLE_GROUP_CHANGES_SAVED',
  SET_ALL_GROUP_GAMES_DATA: 'SET_ALL_GROUP_GAMES_DATA',
  SET_ADDED_TEMP_GROUP_GAMES_DATA: 'SET_ADDED_TEMP_GROUP_GAMES_DATA',
  TOGGLE_ADDED_TEMP_GROUP_GAMES_LOADING: 'TOGGLE_ADDED_TEMP_GROUP_GAMES_LOADING',
  SET_ALL_TEMP_GROUP_GAMES_DATA: 'SET_ALL_TEMP_GROUP_GAMES_DATA',
  TOGGLE_ALL_TEMP_GROUP_GAMES_LOADING: 'TOGGLE_ALL_TEMP_GROUP_GAMES_LOADING',
  SET_CREATED_GROUP: 'SET_CREATED_GROUP',
  SET_CREATED_GROUP_FINAL_STEP_DATA: 'SET_CREATED_GROUP_FINAL_STEP_DATA',
  SET_CREATED_TEMP_GROUP_ID: 'SET_CREATED_TEMP_GROUP_ID',
  SET_TOTAL_GROUP_GAMES: 'SET_TOTAL_GROUP_GAMES',
  SET_TOTAL_TEMP_GROUP_GAMES: 'SET_TOTAL_TEMP_GROUP_GAMES',
  REMOVE_ADDED_GROUP_GAMES: 'REMOVE_ADDED_GROUP_GAMES',
  REMOVE_ADDED_TEMP_GROUP_GAMES: 'REMOVE_ADDED_TEMP_GROUP_GAMES',
  TOGGLE_TEMP_GROUP_MEMBERSHIP_GAMES: 'TOGGLE_TEMP_GROUP_MEMBERSHIP_GAMES',
  TOGGLE_ALL_GROUP_MEMBERSHIP_TEMP_GAMES: 'TOGGLE_ALL_GROUP_MEMBERSHIP_TEMP_GAMES',

  SET_RESOURCES: 'SET_RESOURCES',
  SET_CONTAINER: 'SET_CONTAINER',

  SET_SUBGROUPS: 'SET_SUBGROUPS',
  SET_TOTAL_SUBGROUPS: 'SET_TOTAL_SUBGROUPS',
  SET_ALL_SUBGROUP_GAMES_DATA: 'SET_ALL_SUBGROUP_GAMES_DATA',
  SET_ADDED_SUBGROUP_GAMES_DATA: 'SET_ADDED_SUBGROUP_GAMES_DATA',
  SET_ALL_TEMP_SUBGROUP_GAMES_DATA: 'SET_ALL_TEMP_SUBGROUP_GAMES_DATA',
  SET_ADDED_TEMP_SUBGROUP_GAMES_DATA: 'SET_ADDED_TEMP_SUBGROUP_GAMES_DATA',
  SET_TOTAL_TEMP_SUBGROUP_GAMES: 'SET_TOTAL_TEMP_SUBGROUP_GAMES',
  SET_TOTAL_SUBGROUP_GAMES: 'SET_TOTAL_SUBGROUP_GAMES',
  UPDATE_SUBGROUP: 'UPDATE_SUBGROUP',
  TOGGLE_ALL_SUBGROUP_GAMES_LOADING: 'TOGGLE_ALL_SUBGROUP_GAMES_LOADING',
  TOGGLE_ADDED_SUBGROUP_GAMES_LOADING: 'TOGGLE_ADDED_SUBGROUP_GAMES_LOADING',
  TOGGLE_All_TEMP_SUBGROUP_GAMES_LOADING: 'TOGGLE_All_TEMP_SUBGROUP_GAMES_LOADING',
  TOGGLE_ADDED_TEMP_SUBGROUP_GAMES_LOADING: 'TOGGLE_ADDED_TEMP_SUBGROUP_GAMES_LOADING',
  TOGGLE_SUBGROUP_MEMBERSHIP_GAMES: 'TOGGLE_SUBGROUP_MEMBERSHIP_GAMES',
  TOGGLE_TEMP_SUBGROUP_MEMBERSHIP_GAMES: 'TOGGLE_TEMP_SUBGROUP_MEMBERSHIP_GAMES',
  REMOVE_ADDED_SUBGROUP_GAMES: 'REMOVE_ADDED_SUBGROUP_GAMES',
  REMOVE_ADDED_TEMP_SUBGROUP_GAMES: 'REMOVE_ADDED_TEMP_SUBGROUP_GAMES',
  TOGGLE_IS_CREATE_EDIT_SUBGROUP_MODAL_OPEN: 'TOGGLE_IS_CREATE_EDIT_SUBGROUP_MODAL_OPEN',
  TOGGLE_SUBGROUP_LOADING: 'TOGGLE_SUBGROUP_LOADING',

  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_TYPES: 'SET_TYPES',
  SET_AGGREGATORS: 'SET_AGGREGATORS',
  SET_PROVIDERS: 'SET_PROVIDERS',
  SET_COMPANIES: 'SET_COMPANIES',
  UPDATE_CASINO_GAMES_ORDER: 'UPDATE_CASINO_GAMES_ORDER',
  UPDATE_PROMOTED_CASINO_GAMES_ORDER: 'UPDATE_PROMOTED_CASINO_GAMES_ORDER',
  UPDATE_POPULAR_CASINO_GAMES_ORDER: 'UPDATE_POPULAR_CASINO_GAMES_ORDER',
  UPDATE_GROUPS_ORDER: 'UPDATE_GROUPS_ORDER',
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',
  SET_STATUS_MESSAGE: 'SET_STATUS_MESSAGE',
  CLEAR_STATUS_MESSAGE: 'CLEAR_STATUS_MESSAGE',
  TOGGLE_IS_DRAGGING: 'TOGGLE_IS_DRAGGING',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  SET_USER: 'SET_USER',
  SET_USER_MODULES: 'SET_USER_MODULES',
  SET_ALL_TPA_GAMES: 'SET_ALL_TPA_GAMES',
  SET_TPA_AGGREGATORS: 'SET_TPA_AGGREGATORS',
  SET_TPA_CATEGORIES: 'SET_TPA_CATEGORIES',
  SET_TPA_PROVIDERS: 'SET_TPA_PROVIDERS',
  SET_TPA_TYPES: 'SET_TPA_TYPES',
  SET_MESSAGES_FROM_API: 'SET_MESSAGES_FROM_API',
  SET_CASINO_CAROUSELS: 'SET_CASINO_CAROUSELS',
  SET_CASINO_CAROUSEL_TYPES: 'SET_CASINO_CAROUSEL_TYPES',
};
