import constants from '@/utility/constants';
import http from './http';

const { GROUP_GAMES_PAGE_SIZE } = constants;

const getGroupGames = ({
  companyId, page = 1, size = GROUP_GAMES_PAGE_SIZE, ...params
}) => {
  const url = `casino/v2/admin/companies/${companyId}/games`;

  return http
    .get(url, {
      params: {
        ...params,
        page,
        size,
      },
    })
    .then((res) => res.data);
};

const getTempGroupGames = ({
  companyId, gamesListId, page = 1, size = GROUP_GAMES_PAGE_SIZE, ...params
}) => {
  const url = `casino/v2/admin/companies/${companyId}/temp-games/${gamesListId}`;

  return http
    .get(url, {
      params: {
        ...params,
        page,
        size,
      },
    })
    .then((res) => res.data);
};

const getGroups = ({ companyId, ...params }) => {
  const url = `casino/v2/admin/companies/${companyId}/groups`;

  return http.get(url, {
    params: {
      ...params,
      pageSize: 1000,
    },
  }).then((res) => res.data.groups);
};

const createTempGamesListId = ({ companyId, payload, auditData }) => {
  const url = `casino/v2/admin/companies/${companyId}/games-list`;

  return http.post(url, payload, auditData)
    .then((res) => res.data);
};

const createTempGroup = ({ companyId, group }) => {
  const url = `casino/v2/admin/companies/${companyId}/temp-groups`;

  return http.post(url, group)
    .then((res) => res.data);
};

const createGroup = ({
  companyId, group, tempGroupId, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/groups`;

  return http.post(url, group ?? { tempGroupId }, auditData)
    .then((res) => res.data);
};

const editTempGroup = ({ companyId, tempGroupId, group }) => {
  const url = `casino/v2/admin/companies/${companyId}/temp-groups/${tempGroupId}`;

  return http.put(url, group)
    .then((res) => res.data);
};

const editGroup = ({
  companyId, groupId, group, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${groupId}`;

  return http.put(url, group, auditData)
    .then((res) => res.data);
};

const deleteGroup = ({ companyId, groupId, auditData }) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${groupId}`;

  return http.delete(url, auditData)
    .then((res) => res.data);
};

const saveGroupTempGames = ({
  companyId, groupId, gamesListId, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${groupId}/games`;

  return http.put(url, { gamesListId }, auditData)
    .then((res) => res.data);
};

const removeGamesFromGroup = ({
  companyId, gamesListId, payload, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${gamesListId}/games/remove`;

  return http.put(url, payload, auditData)
    .then((res) => res.data);
};

const updateGroupTempGames = ({
  companyId, action, gamesListId, payload, auditData,
}) => {
  const url = `casino/v2/admin/companies/${companyId}/games-list/${gamesListId}/${action.toLowerCase()}`;

  return http.put(url, payload, auditData)
    .then((res) => res.data);
};

export default {
  getGroupGames,
  getTempGroupGames,
  getGroups,
  createTempGamesListId,
  createTempGroup,
  createGroup,
  editTempGroup,
  editGroup,
  deleteGroup,
  saveGroupTempGames,
  removeGamesFromGroup,
  updateGroupTempGames,
};
